.app__header {
    color: var(--primary-color);
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: url("../../assets/images/header.png") center no-repeat;
    background-size: cover;
    clip-path: polygon(100% 0%, 0% 0%, 0.00% 100.00%, 1.67% 99.64%, 3.33% 98.67%, 5.00% 97.34%, 6.67% 96.01%, 8.33% 95.04%, 10.00% 94.68%, 11.67% 95.04%, 13.33% 96.01%, 15.00% 97.34%, 16.67% 98.67%, 18.33% 99.64%, 20.00% 100.00%, 21.67% 99.64%, 23.33% 98.67%, 25.00% 97.34%, 26.67% 96.01%, 28.33% 95.04%, 30.00% 94.68%, 31.67% 95.04%, 33.33% 96.01%, 35.00% 97.34%, 36.67% 98.67%, 38.33% 99.64%, 40.00% 100.00%, 41.67% 99.64%, 43.33% 98.67%, 45.00% 97.34%, 46.67% 96.01%, 48.33% 95.04%, 50.00% 94.68%, 51.67% 95.04%, 53.33% 96.01%, 55.00% 97.34%, 56.67% 98.67%, 58.33% 99.64%, 60.00% 100.00%, 61.67% 99.64%, 63.33% 98.67%, 65.00% 97.34%, 66.67% 96.01%, 68.33% 95.04%, 70.00% 94.68%, 71.67% 95.04%, 73.33% 96.01%, 75.00% 97.34%, 76.67% 98.67%, 78.33% 99.64%, 80.00% 100.00%, 81.67% 99.64%, 83.33% 98.67%, 85.00% 97.34%, 86.67% 96.01%, 88.33% 95.04%, 90.00% 94.68%, 91.67% 95.04%, 93.33% 96.01%, 95.00% 97.34%, 96.67% 98.67%, 98.33% 99.64%, 100.00% 100.00%);
    padding-bottom: 4rem;
    margin-top: 5.2rem;
  @media screen and (max-width: 600px) {
    margin-bottom: 1rem;
    flex-direction: column-reverse;
  }
  }
  
  .app__header-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    color: var(--primary-color);
    background-color: var(--secondary-color);
    border-radius: 59% 41% 70% 30% / 30% 67% 33% 70%;
    width: 350px;
    height: 350px;
    margin: 2rem;
    text-transform: uppercase;
    @media screen and (max-width: 900px) {
      padding: 1rem;
      gap: 0.5rem;
      border-radius: 53% 47% 69% 31% / 47% 55% 45% 53% ;
      width: 300px;
      height: 300px;
      h2 {
        font-size: 1.2rem;
      }
    }
    @media screen and (max-width: 600px) {
      padding:1rem;
      border-radius: 100% ;
      h2 {
        font-size: 1rem;
      }
    }
      a {
        color: var(--primary-color);
        text-decoration: none;
        font-size: 1rem;
        padding: 0.5rem;
        border: 3px solid var(--primary-color);
        border-radius: 16px;
        filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.5));
        @media screen and (max-width: 900px) {
          font-size: 0.7rem;
          border: 2px solid var(--primary-color);
          font-weight: 600;
        }
      }
  }
  .app__header-contact_infos {
    text-align: center;
  }
  .app_header-infos {
    padding: 2rem;
    width: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(2px);
    border: 5px solid var(--primary-color);
    border-radius: 16px;
    h1 {
      text-align: center;
      color: var(--primary-color);
      padding-bottom: 0.5rem;
    }
    @media screen and (max-width: 900px) {
      width: 50%;
      p {
        font-size: 0.8rem;
        text-align: justify;
      }
    }
    @media screen and (max-width: 600px) {
      border: 3px solid var(--primary-color);
      padding: 0.5rem;
      width: 95%;
      h1 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
        text-align: justify;
      }
    }
  }