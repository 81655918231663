@import "leaflet/dist/leaflet.css";
.app__map-global {
  height: 300px;
  width: 500px;
  @media screen and (max-width: 600px) {
    height: 150px;
    width: 300px;
  }
}
.app__map {
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  z-index: 4;
  position: relative;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 2;
  pointer-events: none;
}
