.container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 50px;

  .box {
    position: relative;
    top: 0;
    padding: 40px;
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: var(--secondary-color);
    transition: 0.5s;

    img {
      position: absolute;
      top: 120px;
      z-index: 1;
      max-width: 80px;
      transition: 0.5s;
      filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.5));
    }
  }
}

.container .box::before {
  content: "";
  position: absolute;
  inset: 30px 30px 30px 30px;
  border-radius: 20px;
  border: 5px solid var(--primary-color);
  filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.5));
  transition: 0.5s;
  z-index: 1;
}

.container .box:hover img {
  opacity: 0;
  z-index: 0;
}

.container .box .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s;
  text-align: center;
  padding: 0.5rem;

  h2 {
    font-size: 1rem;
    color: var(--primary-color);
    text-align: center;
    text-transform: uppercase;
    margin: 0.5rem 0;
  }

  p {
    flex: 1;
    opacity: 0;
    color: var(--primary-color);
    text-align: center;
    font-size: 0.7rem;
  }

  a {
    opacity: 0;
    position: relative;
    top: 20px;
    background: var(--primary-color);
    padding: 0.9rem;
    color: #333;
    border-radius: 20px;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    z-index: 3;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
  }
}

.container .box:hover .content p,
.container .box:hover .content a {
  opacity: 1;
}

.container .box:hover .content h2 {
  top: 40px;
}

.container .box span {
  position: absolute;
  inset: 0;
  rotate: calc(var(--i) * 90deg);
}

.container .box span::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  background-repeat: repeat;
  height: 15px;
  background-image: radial-gradient(
    circle at 10px 15px,
    var(--primary-color) 12px,
    transparent 13px
  );
  background-size: 40px 20px;
  animation: animate 0.5s linear infinite;
  animation-play-state: paused;
}

.container .box span::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  background-repeat: repeat;
  height: 10px;
  background-image: radial-gradient(
    circle at 10px -5px,
    transparent 12px,
    var(--primary-color) 13px
  );
  background-size: 20px 20px;
  animation: animate 0.5s linear infinite;
  animation-play-state: paused;
}

.container .box:hover span::before,
.container .box:hover span::after {
  animation-play-state: running;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}

@media screen and (max-width: 600px) {
  .container {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .box {
      position: relative;
      top: 0;
      padding: 5px;
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      background: var(--secondary-color);
      transition: 0.5s;

      img {
        position: absolute;
        top: 90px;
        z-index: 1;
        max-width: 50px;
        transition: 0.5s;
        filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.5));
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container .box::before {
    inset: 15px 15px 15px 15px;
    border: 2px solid var(--primary-color);
  }
}

@media screen and (max-width: 600px) {
  .container .box .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
    text-align: center;

    h2 {
      font-size: 0.8rem;
      color: var(--primary-color);
      text-align: center;
      text-transform: uppercase;
      margin-top: 0.7rem;
    }

    p {
      flex: 1;
      opacity: 0;
      color: var(--primary-color);
      text-align: center;
      font-size: 0.6rem;
    }

    a {
      opacity: 0;
      position: relative;
      background: var(--primary-color);
      padding: 0.5rem;
      color: #333;
      border-radius: 20px;
      font-weight: 500;
      font-size: 0.7rem;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
      z-index: 3;
      box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
    }
  }
}
