.app__navbar-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: rgba(255,255,255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255,255,255, 0.18);
    position: fixed;
    z-index: 2;
}
.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 250px;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.app__navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 1rem;
  li {
    margin: 0 1rem;
    cursor: pointer;
    flex-direction: column;
    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: column;
      transition: all 0.3s ease-in-out;
      text-transform: uppercase;
      font-weight: 500;
    }
    &:hover {
      a {
        color: var(--secondary-color);
      }
    }
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
}
.app__navbar-contact {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.1s ease-in-out;
    color: var(--primary-color);
    background-color: var(--secondary-color);
    padding: 0.3rem 0.5rem;
    border: 2px solid transparent;
    border-radius: 12px;

    &:hover {
      cursor: pointer;
      color: var(--secondary-color);
      background-color: var(--primary-color);
      border-color: var(--secondary-color);
    }
    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.2rem;
    }
}
.app__navbar-responsive {
  width: 35px;
  height: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 70%;
    height: 70%;

    &:hover {
      color: var(--secondary-color);
      cursor: pointer;
    }
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100000;

    padding: 1rem;

    width: 80%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    background-color: var(--primary-color);
    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      li {
        margin: 1rem;

        a {
          color: #000000;
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }
      .app__navbar-responsive-contact {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        a {
          color: var(--secondary-color)
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}