.app__service {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 6rem;
    @media screen and (max-width: 900px) {
      gap: 4rem;
    }
    @media screen and (max-width: 600px) {
      gap: 2rem;
    }
  }