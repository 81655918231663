@import './index.css';

.App {
  font-family: var(--font-base);
  background-color: var(--primary-color);
  width: 100%;
  min-height: 100vh;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}