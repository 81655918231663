:root {
  --font-base: 'Inter', sans-serif;
  --primary-color: #fafafb;
  --secondary-color: #2388d9;
  --tertiary-color: #333333;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

h1 {
  color: var(--secondary-color);
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
@media screen and (max-width: 900px) {
  h1 {
    font-size: 1.4rem;
  }
}
body {
  --sb-track-color: #3a454a;
  --sb-thumb-color: #5586ca;
  --sb-size: 12px;
}

body::-webkit-scrollbar {
  width: var(--sb-size);
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 14px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 14px;
  border: 2px solid #232e33;
}

@supports not selector(::-webkit-scrollbar) {
  body {
      scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}