.app__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    margin-top: 1rem;
    padding: 0.5rem 1rem;

  }
  .app__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
  }
  .app__footer-links div {
    width: 250px;
    margin: 1rem;
    color: var(--primary-color);
  }
  .app__footer-links_logo {
    display: flex;
    flex-direction: column;
  }
  .app__footer-links_logo img {
    width: 160px;
    height: auto;
    margin-bottom: 1rem;
  }
  .app__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
  }
  .app__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .app__footer-links_div h4 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 1rem;
  }
  .app__footer-links_div p {
    font-family: var(--font-family);
    font-size: 0.8rem;
    line-height: 12px;
    color: #fff;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  
  .app__footer-links_div a {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    margin: 0.5rem 0;
    cursor: pointer;
    text-decoration: none;
  }
  .app__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
  }
  .app__footer-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
  }
  @media screen and (max-width: 850px) {
    .app__footer-heading h1 {
      font-size: 44px;
      line-height: 50px;
    }
  }
  @media screen and (max-width: 550px) {
    .app__footer-heading h1 {
      font-size: 34px;
      line-height: 42px;
    }
    .app__footer-links div {
      margin: 1rem 0;
    }
    .app__footer-btn p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  @media screen and (max-width: 550px) {
    .app__footer-heading h1 {
      font-size: 27px;
      line-height: 38px;
    }
  }

  .scrollbar::-webkit-scrollbar {
    width: var(--sb-size);
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 14px;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 12px;
  }
  
  @supports not selector(::-webkit-scrollbar) {
    .scrollbar {
        scrollbar-color: var(--sb-thumb-color)
                       var(--sb-track-color);
    }
  }